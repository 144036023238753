.dash-nav {
    display: flex;
    height: 60px;
}

.dash-nav a {
    padding-top: 2px;
    border-bottom: 2px solid white;
}

.dash-nav .active, .dash-nav a:hover {
    border-bottom: 2px solid #2c80ff;
}

/* dashboard */
.dash-menu-item {
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #2c80ff;
    font-weight: 500;
    -webkit-transition: 0.3s;
    -webkit-display: flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    -webkit-font-size: 14px;
    -webkit-color: #2c80ff;
    -webkit-font-weight: 500;
    -moz-transition: 0.3s;
    -moz-display: flex;
    -moz-justify-content: center;
    -moz-align-items: center;
    -moz-font-size: 14px;
    -moz-color: #2c80ff;
    -moz-font-weight: 500;
}

.spinning {
    transition: 0.2s;
    animation: spinning 2s infinite linear;
}

@keyframes spinning {
    100% {
        rotate: 360deg;
    }
}

.bg-violet {
    background: linear-gradient(135deg, #4059a0 0%, #6e7eef 100%)
}

.bg-purple {
    background: linear-gradient(135deg, #a04075 0%, #ef6e9d 100%)
}

.bg-yellow {
    background: linear-gradient(135deg, #f9be1f 0%, #fb8c2d 100%)
}

.bg-green {
    background: linear-gradient(135deg, #46a040 0%, #c2ef6e 100%)
}

.bg-gray {
    background: linear-gradient(135deg, #d2dde9 0%, #d2dde9 100%);
}

.acc-bal-div {
    background-image: linear-gradient(to right, #3550e0, #2862ab);
}

.bal-icon-1 {
    background: linear-gradient(135deg, #9f92e9 0%, #a56ba9 100%);
}

.bal-icon-2 {
    background: linear-gradient(135deg, #f9be1f 0%, #fb8c2d 100%);
}

.bal-icon-3 {
    background: linear-gradient(135deg, #d88b59 0%, #cfbc5e 100%);
}

.bal-icon-4 {
    background: linear-gradient(135deg, #ec99e8 0%, #a943ab 100%);
}

.bal-icon-5 {
    background: linear-gradient(135deg, #5960d8 0%, #5ea0cf 100%);
}

.bal-icon-6 {
    background: linear-gradient(135deg, #d2dde9 0%, #d2dde9 100%);
}

.acc-bal-2 {
    background-image: url("../../../public/img/bgworks.png");
}

.acc-bal .fan {
    box-shadow: -6.894px 5.785px 20px 14px rgb(255 255 255 / 10%);
    background: linear-gradient(135deg, #f9ed1f 0%, #fb8c2d 100%);
    animation: animationpulse 2s infinite;
    -webkit-animation: animationpulse 2s ease-out;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: infinite;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    height: 88px;
    width: 93px;
    border-radius: 9999px;
}

.acc-bal .fan img {
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes animationpulse {
    0% {
        transform: scale(1, 1);
        -moz-box-shadow: 0 0 0 0 inherit;
        box-shadow: 0 0 0 0 inherit;
    }

    10% {
        transform: scale(1.1, 1.1);
    }

    15% {
        transform: scale(1, 1);
    }

    70% {
        -moz-box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 15px rgb(0 0 0 / 0%);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
    }
}