.login__form {
    rotate: 0deg;
    animation: loginForm linear 0.3s;
    -moz-animation: loginForm linear 0.3s;
    -webkit-animation: loginForm linear 0.3s;
}

.slideUp {
    transition: all 0.3s ease-out;
    transform: rotateZ(360deg) scale(0);
}

@keyframes loginForm {
    0% {
        scale: 0;
    }

    70%, 80% {
        scale: 1.2
    }
}